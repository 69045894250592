import React from 'react'
import * as NavbarStyles from './Navbar.module.css'
import { StaticImage } from 'gatsby-plugin-image'

const Navbar = () => {
    return (
        <nav className={NavbarStyles.navbar}>
            <div className={NavbarStyles.container}>
            <StaticImage alt="MDT Faucet" src="../../images/logo.png" placeholder="blurred" className={NavbarStyles.logo}></StaticImage>
            <a href="https://mefi.app/" target="_blank" rel="noopener noreferrer">Go to MeFi Oracle</a>
            </div>
        </nav>
    )
}

export default Navbar