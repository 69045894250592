import React, {useState, useEffect, useRef} from 'react'
import Layout from '../layouts/default.js'
import * as IndexStyles from './index.module.css'
import TextField from '../components/Textfield/Textfield.js'
import TextButton from '../components/Buttons/TextButton.js'
import Recaptcha from 'react-recaptcha'
import { keccak256 } from 'js-sha3'
import Banner from '../components/Banner/Banner.js'
import LoadingSpinner from '../components/LoadingSpinner/LoadingSpinner.js'
import IconLiveHelp from '../components/Icons/LiveHelp.js'


const IndexPage = () => {
    const [address, setAddress] = useState('')
    const [isValidAddress, setIsValidAddress] = useState({
        value: true
    })
    const [isPendingForVerification, setPendingForVerification] = useState(false)
    const [recaptchaResponse, setRecaptchaResponse] = useState(null)
    const [response, setResponse] = useState(null)
    const [isLoading, setIsLoading] = useState(false)

    const handleAddressChange = (event) => {
        setAddress(event.target.value)
    }

    useEffect(() => {
        if (address.length === 0) {
            setIsValidAddress({
                value: null
            })
            setPendingForVerification(false)
        } else if (isAddress(address)) {
            setIsValidAddress({
                value: true
            })
            setPendingForVerification(true)
        } else {
            setIsValidAddress({
                value: false,
                message: "Please enter a valid address"
            })
            setPendingForVerification(false)
        }
    }, [address])

    const recaptchaRef = useRef(null);

    const resetRecaptcha = () => {
        recaptchaRef.current.reset();
        setRecaptchaResponse(null)
    }

    const isAddress = (address) => {
        if (!/^(0x)?[0-9a-f]{40}$/i.test(address)) {
            // check if it has the basic requirements of an address
            return false;
        } else if (/^(0x)?[0-9a-f]{40}$/.test(address) || /^(0x)?[0-9A-F]{40}$/.test(address)) {
            // If it's all small caps or all all caps, return true
            return true;
        } else {
            // Otherwise check each case
            address = address.replace('0x', '');
            const addressHash = keccak256(address.toLowerCase());
            for (var i = 0; i < 40; i++) {
                // the nth letter should be uppercase if the nth digit of casemap is 1
                if ((parseInt(addressHash[i], 16) > 7 && address[i].toUpperCase() !== address[i]) || (parseInt(addressHash[i], 16) <= 7 && address[i].toLowerCase() !== address[i])) {
                    return false;
                }
            }
            return true;
        }
    }

    const requestMDT = async () => {
        setIsLoading(true)
        setResponse(null)

        const requestLink = "https://api.rinkeby.mdt.io/mdt_request"
        let response = await fetch(requestLink, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(
                {
                    "recaptcha_response": recaptchaResponse,
                    "to": address
                }
            )
        })

        if (!response.ok) {
            let errorData = await response.json()
            setResponse(errorData)
            console.error("Error in requesting MDT: ", errorData)
        } else {
            let data = await response.json()
            setResponse(data)
            console.log("response data: ", data)
        }
        setIsLoading(false)
        resetRecaptcha()
    }

    const verifyRecaptchaCallback = (response) => {
        setRecaptchaResponse(response)
    }

    const expiredRecaptchaCallback = () => {
        setRecaptchaResponse(null)
    }

    const closeBanner = () => {
        setResponse(null)
    }

    return (
        <Layout pageTitle="MDT Faucet | Get MDT on Rinkeby Testnet">
            <div className={IndexStyles.container}>
                <h1 className={IndexStyles.title}>
                    Get Free MDT on Rinkeby Testnet
                </h1>
                <div className={IndexStyles.form}>
                    <TextField id="address" labelText="Your Rinkeby Testnet Address" placeholderText="0x" value={address} setValue={setAddress} handleAddressChange={handleAddressChange} valid={isValidAddress}></TextField>

                    {
                        isPendingForVerification ?
                            <Recaptcha
                                className={IndexStyles.recaptchaWrapper}
                                ref={recaptchaRef}
                                sitekey="6LfwETMcAAAAACDSmYB8iHIpFWvL8_DZZgyuV_5G"
                                verifyCallback={verifyRecaptchaCallback}
                                expiredCallback={expiredRecaptchaCallback}
                            /> : null
                    }

                    <TextButton component='button' isDisabled={!(isValidAddress.value && recaptchaResponse) || isLoading} onClick={requestMDT}>
                        {isLoading ? <LoadingSpinner /> : 'Get Free Test MDT'}
                    </TextButton>
                </div>
                <div class={IndexStyles.getEth}>
                    <IconLiveHelp></IconLiveHelp>
                    <p>
                        Need testnet ETH? Get ETH from <a href="https://faucet.rinkeby.io/" target="_blank" rel="noopener noreferrer">Rinkeby testnet Faucet</a>
                    </p>
                </div>
                <Banner response={response} closeBanner={closeBanner} />
            </div>
        </Layout>
    )
}

export default IndexPage