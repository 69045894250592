import React from 'react'
import * as TextButtonStyles from './TextButton.module.css'

const Button = ({ children, component, type, href, onClick, isDisabled }) => {
    switch (component) {
        case 'input':
            return <input className={TextButtonStyles.button} type={type || 'button'} value={children} disabled={isDisabled}></input>
        case 'a':
            return <a href={href} onClick={onClick}>{children}</a>
        case 'button':
        default:
            return <button className={TextButtonStyles.button} onClick={onClick} disabled={isDisabled}>{children}</button>
    }
}

export default Button