import React, { useState, useEffect } from 'react'
import * as TextfieldStyles from './Textfield.module.css'

const TextField = ({ id, labelText, placeholderText, value, setValue, handleAddressChange, valid }) => {
    const [validClass, setValidClass] = useState("")

    const pasteFromClipboard = async () => {
        try {
            const text = await navigator.clipboard?.readText()
            if (text) setValue(text)
        } catch (error) {
            console.error(error)
        }
    }

    useEffect(() => {
        if (valid.value !== null && valid.value !== undefined) {
            if (valid.value) {
                setValidClass("")
            } else {
                setValidClass(TextfieldStyles.invalid)
            }
        } else {
            setValidClass("")
        }
    }, [valid])

    return (
        <div className={TextfieldStyles.textfield + " " + validClass}>
            <label htmlFor={id}>
                {labelText}
            </label>
            <div className={TextfieldStyles.inputWrapper}>
                <input id={id} type="text" value={value} placeholder={placeholderText} onChange={handleAddressChange}>
                </input>
                <button onClick={pasteFromClipboard}>
                    Paste
                </button>
            </div>
            <span className="helper-message">{valid.message}</span>
        </div>
    )
}

export default TextField