import * as React from 'react'
import '../styles/global.css'
import * as LayoutStyles from './default.module.css'
import Navbar from '../components/Navbar/Navbar.js'
import Footer from '../components/Footer/Footer.js'

const DefaultLayout = ({ pageTitle, children }) => {
    return (
        <div className={LayoutStyles.layout}>
            <title>{pageTitle || 'MDT Faucet'}</title>
            <div className={LayoutStyles.app}>
                <Navbar></Navbar>
                <main className={LayoutStyles.main}>
                    {children}
                </main>
                <Footer className={LayoutStyles.footer}></Footer>
            </div>
        </div>
    )
}

export default DefaultLayout