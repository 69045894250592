import * as React from 'react'
import * as FooterStyles from './Footer.module.css'

const Footer = () => {
    return (
        <footer className={FooterStyles.footer}>
            <div>
                <div>
                    <span>All rights reserved © </span>
                    <a href="https://mdt.io/" target="_blank" rel="noopener noreferrer">Measurable Foundation Ltd.</a>
                </div>
                <div>
                    <a href="https://mdt.io/terms" target="_blank" rel="noopener noreferrer">Terms of Use</a>
                    <span>|</span>
                    <a href="https://mdt.io/privacy" target="_blank" rel="noopener noreferrer">Privacy Policy</a>
                </div>
            </div>
        </footer>
    )
}

export default Footer