import React from 'react'
import * as BannerStyles from './Banner.module.css'
import { motion } from 'framer-motion'
import IconClose from '../Icons/Close'
import IconButton from '../Buttons/IconButton'

const Banner = ({ response, closeBanner }) => {
    const open = response !== null
    const animatedStyle = open ? {
        opacity: 1,
        y: -50,
        x: '-50%',
    } : {
        opacity: 0,
        y: 500,
        x: '-50%',
    }

    if (!response) {
        return (
            <motion.div className={BannerStyles.banner} animate={animatedStyle} transition={{ type: "spring", stiffness: 50, duration: 0.2 }}>
                <p></p>
                <IconButton onClick={closeBanner}>
                    <IconClose></IconClose>
                </IconButton>
            </motion.div>
        )
    } else if (response?.status_code >= 400) {
        return (
            <motion.div className={BannerStyles.banner + " " + BannerStyles.error} animate={animatedStyle} transition={{ type: "spring", stiffness: 50, duration: 0.2 }}>
                <p>{response.message}</p>
                <IconButton onClick={closeBanner}>
                    <IconClose></IconClose>
                </IconButton>
            </motion.div>
        )
    } else {
        return (
            <motion.div className={BannerStyles.banner + " " + BannerStyles.success} animate={animatedStyle} transition={{ type: "spring", stiffness: 50, duration: 0.2 }}>
                <p>
                    Successfully got {response?.amount} MDT on Rinkeby Testnet. Transaction Hash: <a href={`https://rinkeby.etherscan.io/tx/${response?.tx}`} target='_blank' rel="noopener noreferrer">{response?.tx}</a>
                </p>
                <IconButton onClick={closeBanner}>
                    <IconClose></IconClose>
                </IconButton>
            </motion.div>
        )
    }
}

export default Banner