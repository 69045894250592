import * as React from 'react'
import { loadingSpinner } from './LoadingSpinner.module.css'

const LoadingSpinner = () => {
    return (
        // source: https://loading.io/css/
        <div className={loadingSpinner}>
            <div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    )
} 

export default LoadingSpinner