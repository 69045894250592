import React from 'react'
import * as IconButtonStyles from './IconButton.module.css'

const IconButton = ({ children, onClick }) => {
    return (
        <button className={IconButtonStyles.iconButton} onClick={onClick}>
            {children}
        </button>
    )
}

export default IconButton